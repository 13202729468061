<template>
  <div>
    <div class="row mx-2 my-0">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          theme-card
          border-0
          p-5
        "
      >
        <h1>Company Profile</h1>
        <div class="row mt-5 ml-0 desktop-only">
          <div>Show In Reports</div>
        </div>

        <form @submit.prevent="submit" class="mt-3" id="form">
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_name_in_report"
              />
            </div>
            <label
              for="input-control-first-name"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Name</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-first-name"
                autocomplete="off"
                v-model="companyProfile.name"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_phone_number_in_report"
              />
            </div>
            <label
              for="input-control-phone-number"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Phone Number</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-phone-number"
                autocomplete="off"
                v-model="companyProfile.phone_number"
                class="form-control"
              />
              <span class="hintText"></span>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_location_in_report"
              />
            </div>
            <label
              for="input-control-location"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Location</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-location"
                autocomplete="off"
                v-model="companyProfile.location"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_insurer_in_report"
              />
            </div>
            <label
              for="input-control-insurer"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Insurer</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-insurer"
                autocomplete="off"
                v-model="companyProfile.insurer"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_lawyer_in_report"
              />
            </div>
            <label
              for="input-control-lawyer"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Lawyer</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-lawyer"
                autocomplete="off"
                v-model="companyProfile.lawyer"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_bio_in_report"
              />
            </div>
            <label
              for="input-control-bio"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Bio</label
            >
            <div class="col">
              <textarea
                type="textarea"
                id="input-control-bio"
                autocomplete="off"
                v-model="companyProfile.bio"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_hst_number_in_report"
              />
            </div>
            <label
              for="input-control-hst-number"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >HST Number</label
            >

            <div class="col">
              <input
                type="text"
                id="input-control-hst-number"
                autocomplete="off"
                v-model="companyProfile.hst_number"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_email_in_report"
              />
            </div>
            <label
              for="input-control-email"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Email</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-email"
                autocomplete="off"
                v-model="companyProfile.email"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_url_in_report"
              />
            </div>
            <label
              for="input-control-url"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >URL</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-url"
                autocomplete="off"
                v-model="companyProfile.url"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="
                  companyProfile.show_billing_report_description_in_report
                "
              />
            </div>
            <label
              for="input-control-billing-report"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Billing Report Description</label
            >
            <div class="col">
              <textarea
                type="textarea"
                id="input-control-billing-report"
                autocomplete="off"
                v-model="companyProfile.billing_report_description"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="
                  companyProfile.show_payment_report_description_in_report
                "
              />
            </div>
            <label
              for="input-control-payment-report"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Payment Report Description</label
            >
            <div class="col">
              <textarea
                type="textarea"
                id="input-control-payment-report"
                autocomplete="off"
                v-model="companyProfile.payment_report_description"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-if="false"
                v-model="companyProfile.show_logo_in_report"
              />
            </div>
            <label
              for="input-control-logo"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Logo</label
            >
            <div class="col">
              <div class="custom-file">
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file-input"
                  id="input-control-logo"
                  autocomplete="off"
                  @change="onLogoFileSelected"
                />
                <label class="custom-file-label">{{
                  selectedFile ? selectedFile.name : "Choose File"
                }}</label>
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <i
                class="fa fa-info-circle pointer-cursor"
                v-tooltip.top="
                  `Second logo can be accessed under settings toggle when printing forms and reports`
                "
              ></i>
            </div>
            <label
              for="input-control-logo"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Second Logo</label
            >
            <div class="col">
              <div class="custom-file">
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file-input"
                  id="input-control-logo"
                  autocomplete="off"
                  @change="onSecondLogoFileSelected"
                />
                <label class="custom-file-label">{{
                  secondSelectedFile ? secondSelectedFile.name : "Choose File"
                }}</label>
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <i
                class="fa fa-info-circle pointer-cursor"
                v-tooltip.top="
                  `The Oliver bot logo will appear on the Oliver logo bubble and messages avatars`
                "
              ></i>
            </div>
            <label
              for="input-control-logo"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Oliver Bot Logo</label
            >
            <div class="col">
              <div class="custom-file">
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file-input"
                  id="input-control-logo"
                  autocomplete="off"
                  @change="onBotLogoFileSelected"
                />
                <label class="custom-file-label">{{
                  botLogoSelectedFile ? botLogoSelectedFile.name : "Choose File"
                }}</label>
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                v-model="companyProfile.show_signature_in_report"
              />
            </div>
            <label
              for="input-control-signature"
              class="
                col-10 col-md-2 col-lg-2 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Signature</label
            >
            <div class="col">
              <div class="custom-file">
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file-input"
                  id="input-control-signature"
                  autocomplete="off"
                  @change="onSignatureFileSelected"
                />
                <label class="custom-file-label">{{
                  selectedSignatureFile
                    ? selectedSignatureFile.name
                    : "Choose File"
                }}</label>
              </div>
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                id="input-control-date"
                v-model="companyProfile.show_date_of_loss_in_report"
              />
            </div>
            <label
              for="input-control-date"
              class="
                col-11 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Date Of Loss</label
            >
          </div>

          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                id="input-control-claim-number"
                v-model="companyProfile.show_claim_number_in_report"
              />
            </div>
            <label
              for="input-control-claim-number"
              class="
                col-11 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Claim Number</label
            >
          </div>
          <div class="form-group row align-items-center">
            <div class="col-1 float-right" style="text-align: center">
              <input
                type="checkbox"
                style="height: 42px"
                id="input-control-funding-source"
                v-model="companyProfile.show_funding_source_in_report"
              />
            </div>
            <label
              for="input-control-funding-source"
              class="
                col-11 col-form-label
                align-items-center
                justify-content-start
                input-label
              "
              >Funding Source</label
            >
          </div>

          <button
            type="submit"
            class="mt-3 btn btn-theme save-button"
            :disabled="saving"
          >
            <span
              ><i class="mr-2 fas fa-check-circle fa-fw" v-if="!saving"></i>
              <i class="fa fa-spin fa-circle-notch" v-if="saving"></i>
              Update</span
            >
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "companyProfile",
  computed: {
    ...mapState({
      companyProfile: (state) => state.settings.companyProfile,
    }),
  },
  created() {
    this.getProfile();
  },
  data() {
    return {
      phonesList: [],
      addressList: [],
      selectedFile: null,
      secondSelectedFile: null,
      botLogoSelectedFile: null,
      selectedSignatureFile: null,
      saving: false,
    };
  },

  methods: {
    ...mapActions({
      getProfile: "settings/getCompanyProfile",
      updateProfile: "settings/updateCompanyProfile",
      createProfile: "settings/createCompanyProfile",
      updateSignaturePhoto: "settings/updateSignaturePhoto",
      updateLogoPhoto: "settings/updateLogoPhoto",
      updateBotLogo: "settings/updateBotLogo",
    }),
    onLogoFileSelected(event) {
      this.selectedFile = event.target.files[0];
    },
    onSecondLogoFileSelected(event) {
      this.secondSelectedFile = event.target.files[0];
    },
    onBotLogoFileSelected(event) {
      this.botLogoSelectedFile = event.target.files[0];
    },
    onSignatureFileSelected(event) {
      this.selectedSignatureFile = event.target.files[0];
    },
    submit() {
      if (this.companyProfile.id) {
        const promises = [];
        if (this.selectedFile) {
          const data = {
            id: this.companyProfile.id,
            logo: this.selectedFile,
          };
          promises.push(this.updateLogoPhoto(data));
        }
        if (this.selectedSignatureFile) {
          const data = {
            id: this.companyProfile.id,
            logo: this.selectedSignatureFile,
          };
          promises.push(this.updateSignaturePhoto(data));
        }
        if (this.secondSelectedFile) {
          const data = {
            id: this.companyProfile.id,
            second_logo: this.secondSelectedFile,
          };
          promises.push(this.updateLogoPhoto(data));
        }
        if (this.botLogoSelectedFile) {
          const data = {
            id: this.companyProfile.id,
            bot_logo: this.botLogoSelectedFile,
          };
          promises.push(this.updateBotLogo(data));
        }
        this.saving = true;
        this.$forceUpdate();
        Promise.all(promises).then((values) => {
          if (this.selectedFile && values[0]) {
            this.companyProfile.logo = values[0];
          }
          if (this.selectedSignatureFile && promises.length > 1 && values[1]) {
            this.companyProfile.signature = values[1];
          } else if (
            this.selectedSignatureFile &&
            promises.length == 1 &&
            values[0]
          ) {
            this.companyProfile.signature = values[0];
          }
          if (this.secondSelectedFile && promises.length > 2 && values[2]) {
            this.companyProfile.second_logo = values[2];
          } else if (
            this.secondSelectedFile &&
            promises.length > 1 &&
            values[1]
          ) {
            this.companyProfile.second_logo = values[1];
          } else if (
            this.secondSelectedFile &&
            promises.length == 1 &&
            values[0]
          ) {
            this.companyProfile.second_logo = values[0];
          }
          this.updateProfile(this.companyProfile).then(() => {
            this.saving = false;
            this.$forceUpdate();
          });
        });
      } else {
        if (!this.companyProfile.show_signature_in_report) {
          this.companyProfile.show_signature_in_report = false;
        }
        this.createProfile({ ...this.companyProfile });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1024px) {
  .input-label {
    display: flex;
    height: 43px;
  }
  .custom-file {
    min-width: 100px;
  }
}
</style>
